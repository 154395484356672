@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .nav-list {
    color: black;
    transition: 0.3s;
  }
  body {
    font-family: "Urbanist", sans-serif;
  }

  .nav-list:hover {
    cursor: pointer;
    color: white;
    background-color: blue;
    padding: 5px 10px;
    border-radius: 2.5px;
  }

  .clip-path {
    clip-path: polygon(
      48% 21%,
      100% 5%,
      100% 43%,
      100% 100%,
      68% 100%,
      32% 100%,
      0 300%,
      0% 13%,
      0 20%
    );
  }
  .text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .bgimageg {
    background: url("./assets/bg/grow.svg") no-repeat;
    background-size: cover; /* Ensures the image covers the entire container */
    width: 100%; /* Ensures it takes full width */
  }

  .vision1::before {
    content: "Vision"; /* The text to display */
    font-size: 24px; /* Adjust the font size */
    color: #333; /* Customize the text color */
    transform: rotate(-90deg) translateY(50%) translateX(180%);
    font-weight: 600;
    z-index: 1000;
  }

  .vision2::after {
    content: "Value"; /* The text to display */
    font-size: 24px; /* Adjust the font size */
    color: #347b33; /* Customize the text color */
    transform: rotate(90deg) translateY(100%) translateX(30%);
    font-weight: 600;
    z-index: 1000;
    bottom: 10%;
    right: 13%;

    position: absolute;
  }

  .box {
    width: 200px;
    height: 200px;
    background: var(--accent);
  }

  @media only screen and (min-width: 450px) and (max-width: 600px) {
    .vision2::after {
      transform: rotate(90deg) translateY(100%) translateX(0%) !important;
    }
    .vision1::before {
      transform: rotate(-90deg) translateY(50%) translateX(100%);
    }
  }

  @media only screen and (min-width: 610px) and (max-width: 790px) {
    .vision2::after {
      transform: rotate(90deg) translateY(420%) translateX(0%);
    }
    .vision1::before {
      transform: rotate(-90deg) translateY(50%) translateX(100%);
    }
  }

  @media only screen and (max-width: 440px) {
    .vision2::after {
      transform: rotate(90deg) translateY(-60%) translateX(0%);
    }
    .vision1::before {
      transform: rotate(-90deg) translateY(50%) translateX(100%);
    }
  }

  @media only screen and (min-width: 440px) and (max-width: 600px) {
    .vision2::after {
      transform: rotate(90deg) translateY(0%) translateX(0%);
    }
    .vision1::before {
      transform: rotate(-90deg) translateY(50%) translateX(100%);
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 760px) {
    .vision2::after {
      transform: rotate(90deg) translateY(350%) translateX(0%);
    }
    .vision1::before {
      transform: rotate(-90deg) translateY(50%) translateX(100%);
    }
  }

  @media only screen and (min-width: 760px) and (max-width: 1150px) {
    .vision2::after {
      transform: rotate(90deg) translateY(-270%) translateX(0%);
      bottom: 10%;
    }

    .vision1::before {
      transform: rotate(90deg) translateY(35%) translateX(-100%);
    }
  }
  @media only screen and (min-width: 1150px) and (max-width: 1210px) {
    .vision2::after {
      transform: rotate(90deg) translateY(-200%) translateX(10%);
      bottom: 10%;
    }
  }

  @media only screen and (min-width: 1210px) and (max-width: 1450px) {
    .vision2::after {
      bottom: 5%;
      transform: rotate(90deg) translateY(-10%) translateX(0%);
    }
  }

  .bgimage {
    background: url("https://res.cloudinary.com/dlfqmwzzf/image/upload/v1729173384/site/home-1.svg");
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .hide {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    padding: 28px;
    color: #ffffff;
    background-color: #384878;
    transform: translateY(100%);
    transition: transform 0.5s;
  }
  .show {
    overflow: hidden;
  }

  .show:hover img {
    filter: blur(5px);
    background-color: #222;
  }

  .show:hover .hide {
    transform: translateY(0);
    background-color: red;
  }

  * {
    font-family: "Urbanist", sans-serif;
  }

  .testimonial {
    width: 500px;
    max-width: 100%;
    background: #ffca52;
    padding: 4em 3em;
    display: flex;
    align-items: flex-end;
    position: relative;
    box-shadow: 0 2px 2px hsl(0deg 0% 0%/0.075), 0 3px 3px hsl(0deg 0% 0%/0.075),
      0 5px 5px hsl(0deg 0% 0%/0.075), 0 9px 9px hsl(0deg 0% 0%/0.075),
      0 17px 17px hsl(0deg 0% 0%/0.075);
    position: relative;
    &:after {
      content: "";
      border: 8px solid navy;
      border-radius: 50px;
      width: 85%;
      height: 120%;
      position: absolute;
      z-index: -1;
      left: 1.5em;
      top: -2em;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: -6.2em;
      left: 5em;
      z-index: 1;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 70px 100px 0 0;
      border-color: navy transparent transparent transparent;
    }
    .quote {
      position: absolute;
      font-size: 3em;
      width: 40px;
      height: 40px;
      background: navy;
      color: #fff;
      text-align: center;
      line-height: 1.25;
      &.open {
        top: 0;
        left: 0;
      }
      &.close {
        bottom: 0;
        right: 0;
      }
    }
    p {
      width: 60%;
      display: inline-block;
      font-weight: bold;
      font-size: 1.25em;
    }
    .source {
      width: 40%;
      height: 100%;
      position: relative;
      span {
        display: inline-block;
        font-weight: bold;
        font-size: 1.15em;
        position: relative;
        margin-left: 1rem;
        text-align: right;
        &:before {
          content: "\2014";
          display: inline;
          margin-right: 5px;
        }
      }
    }
    .image {
      transform: rotate(-5deg);
      position: absolute;
      top: 0.5em;
      right: 1.5em;
      img {
        border: 10px solid #fff;
        margin: 0;
        padding: 0;
      }
      .clip {
        border: 2px solid #222;
        border-right: none;
        height: 75px;
        width: 20px;
        position: absolute;
        right: 30%;
        top: -15%;
        border-radius: 25px;
        &:before {
          content: "";
          position: absolute;
          top: -1px;
          right: 0;
          height: 10px;
          width: 16px;
          border: 2px solid #222;
          border-bottom: none;
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
          z-index: 99;
        }
        &:after {
          content: "";
          position: absolute;
          bottom: -1px;
          right: 0;
          height: 40px;
          width: 16px;
          border: 2px solid #222;
          border-top: none;
          border-bottom-left-radius: 25px;
          border-bottom-right-radius: 25px;
          z-index: 99;
        }
      }
    }
  }
}

.bar {
  display: block;
  height: 5px;
  width: 400px;
  background: rgb(21, 116, 0);
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin: 20px auto 20px 0;
  margin: 0 auto;
}

.bar:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #fff;
  -webkit-animation-duration: 2s;
  animation-duration: 6s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}

@keyframes MOVE-BG {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(400px);
    transform: translateX(400px);
  }
}

.product-details {
  display: none;
}

.product:hover .product-details {
  display: block;
  font-size: medium;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 90%;
  height: 50px;
  color: #000;
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px;
}

.product-details span {
  content: "";
  height: 30px;
  width: 60%;
  align-items: start;
  padding: 5px;
  bottom: 40px;
  position: absolute;
  left: 5px;
  border-radius: 5px;
  background-color: red;
}
.product:hover img {
  filter: blur(4px);
}

.circle {
  position: fixed;
  display: none;
  bottom: 10px;
  right: 1%;
  width: 40px;
  height: 40px;
  border-radius: 10%;
  font-size: 25px;
  color: #fff;
  background: #603126;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  box-shadow: 0px 0px 3px #000;
  transition: transform 0.3s ease, background-color 0.3s ease;
}
.circle:hover {
  cursor: pointer;
  background-color: #28601a;
  transform: scale(1.1);
}

.slider {
  width: 50vw;
  height: 80vh;
  overflow: hidden;
  border: 1px solid #ccc;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  min-width: 100%;
  box-sizing: border-box;
}

.focus-animation {
  position: relative;
}

.focus-animation:after {
  content: "";
  position: absolute;
  bottom: -1px; /* Position the animated line at the bottom */
  left: 0;
  width: 0;
  height: 2px; /* Thickness of the line when focused */
  background-color: #28601a; /* Desired color */
  transition: width 0.3s ease-in-out;
}

.focus-animation:focus-within:after {
  width: 100%; /* Fully expand the line on focus */
}
